import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../../_services/rest.service';
import { UtilsService } from '../../../../_services/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Banco, CadastroBanco } from 'app/_models/banco';

@Component({
  selector: 'app-banco',
  templateUrl: './banco.component.html',
  styleUrls: ['/banco.component.less']
})
export class BancoComponent implements OnInit {
  pathId = null

  bancoForm = new FormGroup({
    id: new FormControl(''),
    descricao: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
    sigla: new FormControl('', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]),
  })

  constructor(
    private router: Router,
    private _restService: RestService,
    private _utilsService: UtilsService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) { }

  goBack() {
    this.router.navigate(['/bancos']);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        const id = params.get('id');
        this.pathId = id;
        this.getBanco(parseInt(id));
      }
    })

    this.bancoForm.get('descricao').valueChanges.subscribe(value => {
      this.bancoForm.get('descricao').setValue(value.toUpperCase(), { emitEvent: false })
    });

    this.bancoForm.get('sigla').valueChanges.subscribe(value => {
      this.bancoForm.get('sigla').setValue(value.toUpperCase(), { emitEvent: false })
    });
  }

  onSubmit() {
    if (!this.bancoForm.valid) return;
    if (this.pathId) this.updateBanco()
    else this.addBanco();
  }

  getBanco(id: number) {
    this._restService.getBanco(id).subscribe(
      res => {
        this.bancoForm.patchValue(res);
      }
    )
  }

  addBanco() {
    this._restService.addBanco(this.bancoForm.value as CadastroBanco).subscribe(
      bancos => {
        console.log('Banco adicionado com sucesso:', bancos);
        this._snackBar.open("Banco cadastrado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/bancos']);
      },
      error => {
        this._utilsService.alertErro(error);
      }
    );
  }

  updateBanco() {
    const banco = this.copyBanco()

    this._restService.updateBanco(banco).subscribe(
      _ => {
        this._snackBar.open("Banco atualizado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/bancos']);
      },
      error => {
        const errorMessage = (error && error.error && error.error.errors && error.error.errors.length)
          ? error.error.errors.join(', ')
          : 'Erro desconhecido';
        console.error('Erro: ', errorMessage);
        alert(`Erro: ${errorMessage}`);
      }
    )
  }

  copyBanco(): Banco {
    const banco = {
      id: this.pathId,
      descricao: this.bancoForm.get('descricao').value,
      sigla: this.bancoForm.get('sigla').value,
    }
    return banco
  }

}
