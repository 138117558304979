import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from 'app/_services/rest.service';
import { Location } from "@angular/common";
import { MatSnackBar } from '@angular/material';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Tipos } from 'app/_enums/tipos';
import { Canal } from 'app/_models/canal';
import { AreaAtuacao } from 'app/_models/areaAtuacao';
import { pessoaTipoEnum } from 'app/_enums/pessoaTipo.enum';

@Component({
  selector: 'app-ponto-de-venda',
  templateUrl: "./ponto-de-venda.component.html",
  styleUrls: ["./ponto-de-venda.component.less"]
})
export class PontoDeVendaComponent implements OnInit {

  private searchTermsPDV = new Subject<string>();
  private searchTermsVendedor = new Subject<string>();
  private searchTermsGestor = new Subject<string>();
  private searchTermsSupComercial = new Subject<string>();
  tipoPessoaMap = new Map<string, string>();

  filteredOptionsPDV!: Observable<string[]>;
  filteredOptionsVendedor!: Observable<string[]>;
  filteredOptionsGestor!: Observable<string[]>;
  filteredOptionsSupComercial!: Observable<string[]>;
  canais: Canal[] = [];
  areasAtuacao: AreaAtuacao[] = [];
  isLoading: boolean;
  pathId = null;

  pdvForm = new FormGroup({
    idPonto: new FormControl(''),
    pessoaPonto: new FormControl({ value: '', disabled: true }, Validators.required),
    pdvAtivo: new FormControl(false, Validators.required),
    pessoaVendedor: new FormControl('', Validators.required),
    areaAtuacao: new FormControl('', Validators.required),
    canal: new FormControl('', Validators.required),
    supervisorComercial: new FormControl('', Validators.required),
    gestorComercial: new FormControl('', Validators.required),
  });

  constructor(private route: ActivatedRoute,
    private _restService: RestService,
    private router: Router,
    private location: Location,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getPontosDeVenda();
    this.getVendedores();
    this.getGestores();
    this.getSupervisores();
    this.getCanais();
    this.getAreasAtuacao();

    this.tipoPessoaMap.set(pessoaTipoEnum.DIRETOR, 'DIRETOR');
    this.tipoPessoaMap.set(pessoaTipoEnum.CONTROLE_DE_SALDO, 'CONTROLE DE SALDO');
    this.tipoPessoaMap.set(pessoaTipoEnum.PDV, 'PDV');

    this.route.paramMap.subscribe(params => {
      if (params.get('id') !== null) {
        this.pathId = params.get('id');

        this.getPdvPorId(this.pathId);
      } else {
        this.pdvForm.get('pessoaPonto').reset({ value: '', disabled: false });
      }
    });
  }

  mapTipoPessoa = (tipo: string): string => {
    return this.tipoPessoaMap.get(tipo);
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    this.isLoading = true;

    if (this.pdvForm.valid) {
      this.isLoading = true;

      if (this.pathId) {
        const body = {
          idPonto: this.pdvForm.get('idPonto').value,
          pessoaPonto: {
            id: this.pdvForm.get('pessoaPonto').value.id,
            nome: this.pdvForm.get('pessoaPonto').value.nome,
            tipo: this.pdvForm.get('pessoaPonto').value.tipo,
            cpfCnpj: this.pdvForm.get('pessoaPonto').value.cpfCnpj,
          },
          pdvAtivo: this.pdvForm.get('pdvAtivo').value,
          pessoaVendedor: {
            id: this.pdvForm.get('pessoaVendedor').value.id,
            nome: this.pdvForm.get('pessoaVendedor').value.nome,
            tipo: this.pdvForm.get('pessoaVendedor').value.tipo,
            cpfCnpj: this.pdvForm.get('pessoaVendedor').value.cpfCnpj,
          },
          areaAtuacao: {
            idArea: this.pdvForm.get('areaAtuacao').value,
            descricao: this.areasAtuacao.find(area => area.idArea === this.pdvForm.get('areaAtuacao').value).descricao
          },
          canal: {
            id: this.pdvForm.get('canal').value,
            idCanal: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).idCanal,
            descricao: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).descricao,
            descontoMaximo: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).descontoMaximo,
            descontoMaximoMoto: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).descontoMaximoMoto
          },
          supervisorComercial: {
            id: this.pdvForm.get('supervisorComercial').value.id,
            nome: this.pdvForm.get('supervisorComercial').value.nome,
            tipo: this.pdvForm.get('supervisorComercial').value.tipo,
            cpfCnpj: this.pdvForm.get('supervisorComercial').value.cpfCnpj,
          },
          gestorComercial: {
            id: this.pdvForm.get('gestorComercial').value.id,
            nome: this.pdvForm.get('gestorComercial').value.nome,
            tipo: this.pdvForm.get('gestorComercial').value.tipo,
            cpfCnpj: this.pdvForm.get('gestorComercial').value.cpfCnpj,
          },
        }

        this.atualizarPDV(body);

      } else {
        const body = {
          pessoaPonto: {
            id: this.pdvForm.get('pessoaPonto').value.id,
            nome: this.pdvForm.get('pessoaPonto').value.nome,
            tipo: this.pdvForm.get('pessoaPonto').value.tipo,
            cpfCnpj: this.pdvForm.get('pessoaPonto').value.cpfCnpj,
          },
          pdvAtivo: this.pdvForm.get('pdvAtivo').value,
          pessoaVendedor: {
            id: this.pdvForm.get('pessoaVendedor').value.id,
            nome: this.pdvForm.get('pessoaVendedor').value.nome,
            tipo: this.pdvForm.get('pessoaVendedor').value.tipo,
            cpfCnpj: this.pdvForm.get('pessoaVendedor').value.cpfCnpj,
          },
          areaAtuacao: {
            idArea: this.pdvForm.get('areaAtuacao').value,
            descricao: this.areasAtuacao.find(area => area.idArea === this.pdvForm.get('areaAtuacao').value).descricao
          },
          canal: {
            id: this.pdvForm.get('canal').value,
            idCanal: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).idCanal,
            descricao: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).descricao,
            descontoMaximo: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).descontoMaximo,
            descontoMaximoMoto: this.canais.find(canal => canal.id === this.pdvForm.get('canal').value).descontoMaximoMoto
          },
          supervisorComercial: {
            id: this.pdvForm.get('supervisorComercial').value.id,
            nome: this.pdvForm.get('supervisorComercial').value.nome,
            tipo: this.pdvForm.get('supervisorComercial').value.tipo,
            cpfCnpj: this.pdvForm.get('supervisorComercial').value.cpfCnpj,
          },
          gestorComercial: {
            id: this.pdvForm.get('gestorComercial').value.id,
            nome: this.pdvForm.get('gestorComercial').value.nome,
            tipo: this.pdvForm.get('gestorComercial').value.tipo,
            cpfCnpj: this.pdvForm.get('gestorComercial').value.cpfCnpj,
          },
        }

        this.criarPDV(body);
      }
    } else {
      Object.keys(this.pdvForm.controls).forEach(field => {
        const control = this.pdvForm.get(field);

        if (control.invalid) {
          Object.keys(control.errors).forEach(errorType => {
            console.error(`Campo ${field} tem o erro: ${errorType}`);
          });
        }
      });
      this.isLoading = false;
      console.error('Formulário inválido');
      alert('Erro: formulário inválido');
    }
  }

  getPontosDeVenda() {
    // GET pra listar uma quantidade x de clientes
    this.filteredOptionsPDV = this.searchTermsPDV.pipe(
      // wait 250ms after each keystroke before considering the term
      debounceTime(250),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap(
        (term: string) => this._restService.getPessoas(term, Tipos.PDV).map(item => item.results)
      )
    );
  }

  getVendedores() {
    this.filteredOptionsVendedor = this.searchTermsVendedor.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(
        (term: string) => this._restService.getPessoas(term, Tipos.VENDEDOR).map(item => item.results)
      )
    );
  }

  getGestores() {
    this.filteredOptionsGestor = this.searchTermsGestor.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(
        (term: string) => this._restService.getPessoas(term, Tipos.VENDEDOR /* Tipos.GESTOR*/).map(item => item.results) 
      )
    );
  }

  getSupervisores() {
    this.filteredOptionsSupComercial = this.searchTermsSupComercial.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(
        (term: string) => this._restService.getPessoas(term, Tipos.VENDEDOR).map(item => item.results)
      )
    );
  }

  // Autocomplete
  searchPDV(term: string): void {
    if (term.length > 2) {
      this.searchTermsPDV.next(term.toUpperCase());
    }
  }

  searchVendedor(term: string): void {
    if (term.length > 2) {
      this.searchTermsVendedor.next(term.toUpperCase());
    }
  }

  searchGestor(term: string): void {
    if (term.length > 2) {
      this.searchTermsGestor.next(term.toUpperCase());
    }
  }

  searchSupervisor(term: string): void {
    if (term.length > 2) {
      this.searchTermsSupComercial.next(term.toUpperCase());
    }
  }

  displayFn = (pessoa: any): string => {
    // Para os casos de edição de pdv
    if (this.pdvForm.get('idPonto').value && !pessoa.text) {
      return pessoa && pessoa.nome && pessoa.tipo && pessoa.cpfCnpj && pessoa.id ? `(${this.mapTipoPessoa(pessoa.tipo)}) ${pessoa.cpfCnpj} - ${pessoa.nome}` : '';
    }
    else {
      return pessoa && pessoa.text ? pessoa.text : '';
    }
  }

  getCanais() {
    this._restService.getCanais().subscribe(
      res => {
        this.canais = res;
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    );
  }

  getAreasAtuacao() {
    this._restService.getAreasAtuacao().subscribe(
      res => {
        this.areasAtuacao = res;
      }, error => {
        console.error("Erro: ", error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    );
  }

  criarPDV(body) {
    this._restService.criarPDV(body).subscribe(
      res => {
        console.log("Ponto de Venda cadastrado com sucesso", res);
        this._snackBar.open("Ponto de Venda cadastrado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/pontos-de-venda']);
      }, error => {
        console.error('Erro: ', error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    );
  }

  atualizarPDV(body) {
    this._restService.updatePontoDeVenda(body).subscribe(
      _ => {
        this._snackBar.open("Ponto de Venda atualizado com sucesso", "fechar", {
          duration: 4000,
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/pontos-de-venda']);
      }, error => {
        console.error("Erro ao atualizar ponto de venda: ", error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      })
  }

  getAreaAtuacaoPorId(idArea) {
    this._restService.getAreaAtuacaoById(idArea).subscribe(
      res => {
        if (res) {
          this.pdvForm.get('areaAtuacao').setValue(res.idArea);
        }
      }, error => {
        console.error(`Erro ao buscar a área de id: ${idArea}`, error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    )
  }

  getPdvPorId(pdvId) {
    this._restService.getPdvById(pdvId).subscribe(
      res => {
        if (res) {
          this.pdvForm.patchValue(res);

          // O bind só funciona se setar o id
          this.pdvForm.get('areaAtuacao').setValue(res.areaAtuacao.idArea);
          this.pdvForm.get('canal').setValue(res.canal.id);
        }
      },
      error => {
        console.error("Erro: ", error.error.errors[0]);
        alert(`Erro: ${error.error.errors[0]}`);
      }
    );
  }

}