import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportationButtonComponent } from './exportation-button/exportation-button.component';
import { WarningBarComponent } from './warning-bar/warning-bar.component';
import { MatButtonModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';


@NgModule({
  declarations: [
    ExportationButtonComponent,
    WarningBarComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    ExportationButtonComponent,
    WarningBarComponent
  ]
})
export class SharedModule { }
