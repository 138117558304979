import {NgModule} from '@angular/core';
import {ThemeComponent } from './theme.component';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from "../auth/_guards/auth.guard";
import {RoleGuard} from "../auth/_guards/role.guard";
import { SolarComponent } from './pages/default/solar/solar.component';
import { ConsorcioComponent } from './pages/default/consorcio/consorcio.component';
import { LogisticaComponent } from './pages/default/logistica/logistica.component';
import { IndexComponent } from './pages/default/index/index.component';
import { PessoaComponent } from './pages/default/pessoa/pessoa.component';
import { PontoDeVendaComponent } from './pages/default/ponto-de-venda/ponto-de-venda.component';
import { PontosDeVendaComponent } from './pages/default/pontos-de-venda/pontos-de-venda.component';
import { MenuPerfilComponent } from './pages/default/menu-perfil/menu-perfil.component';
import { TiposPessoaComponent } from './pages/default/tipos-pessoa/tipos-pessoa.component';
import { TipoPessoaComponent } from './pages/default/tipo-pessoa/tipo-pessoa.component';
import { Perfis } from 'app/_enums/perfis.enum';
import { OperacaoEmpresaShowComponent } from './pages/default/operacao-empresa/operacao-empresa-show/operacao-empresa-show.component';
import { ImportarLancamentosComponent } from './pages/default/importar-lancamentos/importar-lancamentos.component';
import { ProdutosNovoComponent } from './pages/default/produtos-novo/produtos-novo.component';
import { ProdutoNovoComponent } from './pages/default/produto-novo/produto-novo.component';
import { BancosComponent } from './pages/default/bancos/bancos.component';
import { ConveniosComponent } from './pages/default/convenios/convenios.component';
import { PoliticaProdutosComponent } from './pages/default/politica-produtos/politica-produtos.component';
import { CanaisComponent } from './pages/default/canais/canais.component';
import { CanalComponent } from './pages/default/canal/canal.component';
import { ConvenioComponent } from './pages/default/convenio/convenio.component';
import { PoliticaProdutoComponent } from './pages/default/politica-produto/politica-produto.component';
import { MovimentacoesComponent } from './pages/default/movimentacoes/movimentacoes.component';
import { MeComponent } from './pages/default/me/me.component';
import { BancoComponent } from './pages/default/banco/banco.component';

const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],
        'canActivateChild': [RoleGuard],
        "children": [
            {
                "path": "index",
                "loadChildren": () => import('./pages/default/home/home.module').then(m => m.HomeModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "indexgestor",
                "loadChildren": () => import('./pages/default/homegestor/homegestor.module').then(m => m.HomeGestorModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                path: 'me', component: MeComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                "path": "carrinho",
                "loadChildren": () => import('./pages/default/carrinho/carrinho.module').then(m => m.CarrinhoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "identificacao",
                "loadChildren": () => import('./pages/default/identificacao/identificacao.module').then(m => m.IdentificacaoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "identificacao/:id",
                "loadChildren": () => import('./pages/default/identificacao/identificacao.module').then(m => m.IdentificacaoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "pagamento",
                "loadChildren": () => import('./pages/default/pagamento/pagamento.module').then(m => m.PagamentoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "confirmacao",
                "loadChildren": () => import('./pages/default/confirmacao/confirmacao.module').then(m => m.ConfirmacaoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "sucesso",
                "loadChildren": () => import('./pages/default/sucesso/sucesso.module').then(m => m.SucessoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "vendas",
                "loadChildren": () => import('./pages/default/vendas/vendas.module').then(m => m.VendasModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "vendas/:idPedido",
                "loadChildren": () => import('./pages/default/vendas/vendas.module').then(m => m.VendasModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "vendas/:dataInicial/:dataFinal/:tipo/:idUnidade/:idPdv/:idConvenio/:matriz/:idProduto",
                "loadChildren": () => import('./pages/default/vendas/vendas.module').then(m => m.VendasModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "venda",
                "loadChildren": () => import('./pages/default/venda/venda.module').then(m => m.VendaModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "indicacoes",
                "loadChildren": () => import('./pages/default/indicacoes/indicacoes.module').then(m => m.IndicacoesModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "agendamento",
                "loadChildren": () => import('./pages/default/agendamento/agendamento.module').then(m => m.AgendamentoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "relatorioindicacoes",
                "loadChildren": () => import('./pages/default/relatorioindicacoes/relatorioindicacoes.module').then(m => m.RelatorioIndicacoesModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "consulta",
                "loadChildren": () => import('./pages/default/consulta/consulta.module').then(m => m.ConsultaModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "moto",
                "loadChildren": () => import('./pages/default/moto/moto.module').then(m => m.MotoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "moto-adm",
                "loadChildren": () => import('./pages/default/motos/motos.module').then(m => m.MotosModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "importar-lancamento",
                component: ImportarLancamentosComponent,
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "extrato",
                "loadChildren": () => import('./pages/default/extrato/extrato.module').then(m => m.ExtratoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "extrato/:dataInicial/:dataFinal/:idPessoa/:text",
                "loadChildren": () => import('./pages/default/extrato/extrato.module').then(m => m.ExtratoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "extrato/tipos/:pesquisa/:tipo/:dataInicial/:dataFinal",
                "loadChildren": () => import('./pages/default/extrato/extrato.module').then(m => m.ExtratoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "perfis",
                "loadChildren": () => import('./pages/default/perfis/perfis.module').then(m => m.PerfisModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "perfis/menu-perfil/:perfil",
                component: MenuPerfilComponent,
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                path: 'convenios', component: ConveniosComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                path: 'convenio', component: ConvenioComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                path: 'convenio/:id', component: ConvenioComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                "path": "lancamento",
                "loadChildren": () => import('./pages/default/lancamento/lancamento.module').then(m => m.LancamentoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            // {
            //     "path": "produto",
            //     "loadChildren": () => import('./pages/default/produto/produto.module').then(m => m.ProdutoModule),
            //     "data": {
            //         expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
            //             Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
            //             Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
            //             Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
            //       }
            // },
            {
                path: 'bancos', component: BancosComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'bancos/banco', component: BancoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'bancos/banco/:id', component: BancoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'produtos', component: ProdutosNovoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'produtos/produto', component: ProdutoNovoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'produtos/produto/:id', component: ProdutoNovoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'politica-produtos', component: PoliticaProdutosComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'politica-produto', component: PoliticaProdutoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                path: 'politica-produto/:id', component: PoliticaProdutoComponent,
                data: {
                    expectedRole: [Perfis.FINANCEIRO, Perfis.DIRETOR, Perfis.SUPORTE_CERTIFICADO, Perfis.SUPORTE_MOTOS]
                }
            },
            {
                "path": "operacao-empresa",
                "loadChildren": () => import('./pages/default/operacao-empresa/operacao-empresa-list/operacao-empresa-list.module').then(m => m.OperacaoEmpresaListModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO]
                  }
            },
            {
                path: 'operacao-empresa/adicionar', component: OperacaoEmpresaShowComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO]
                }
            },
            {
                path: 'operacao-empresa/:operacoesId', component: OperacaoEmpresaShowComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                "path": "conciliar",
                "loadChildren": () => import('./pages/default/conciliar/conciliar.module').then(m => m.ConciliarModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "tarifaCartao",
                "loadChildren": () => import('./pages/default/tarifaCartao/tarifaCartao.module').then(m => m.TarifaCartaoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                  }
            },
            {
                "path": "movimentacoes/:selected_value/:searched_value", component: MovimentacoesComponent,
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO,
                        Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                "path": "movimentacoes", component: MovimentacoesComponent,
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO,
                        Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                "path": "movimentacao",
                "loadChildren": () => import('./pages/default/movimentacao/movimentacao.module').then(m => m.MovimentacaoModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO,
                        Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                "path": "pessoas",
                "loadChildren": () => import('./pages/default/pessoas/pessoas.module').then(m => m.PessoasModule),
                "data": {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'pessoa/:id', component: PessoaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'pessoa', component: PessoaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'tipos-pessoa', component: TiposPessoaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'tipo-pessoa', component: TipoPessoaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'tipo-pessoa/:id', component: TipoPessoaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'ponto-de-venda', component: PontoDeVendaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'ponto-de-venda/:id', component: PontoDeVendaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'pontos-de-venda', component: PontosDeVendaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'solar', component: SolarComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'consorcio', component: ConsorcioComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'logistica', component: LogisticaComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                                    Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                                    Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                                    Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'home', component: IndexComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.GERENTE_COMERCIAL, Perfis.SUPERVISOR_COMERCIAL, Perfis.GESTOR_FRANQUIA,
                        Perfis.PDV, Perfis.FINANCEIRO, Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO,
                        Perfis.FORNECEDOR, Perfis.INDICADOR, Perfis.CLIENTE, Perfis.CONTA_CONCILIACAO,
                        Perfis.CAIXA, Perfis.CONTA_CORRENTE_BANCO, Perfis.CONTROLE_SALDO]
                }
            },
            {
                path: 'canais', component: CanaisComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO,
                        Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                path: 'canal', component: CanalComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO,
                        Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                path: 'canal/:id', component: CanalComponent,
                data: {
                    expectedRole: [Perfis.DIRETOR, Perfis.FINANCEIRO,
                        Perfis.SUPORTE_MOTOS, Perfis.SUPORTE_CERTIFICADO]
                }
            },
            {
                "path": "",
                "redirectTo": "home",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    },
    { path: 'tranferencia-sem-nota', loadChildren: () => import('./pages/default/tranferencia-sem-nota/tranferencia-sem-nota.module').then(m => m.TranferenciaSemNotaModule) }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ThemeRoutingModule {}
