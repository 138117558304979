import 'flatpickr/dist/flatpickr.css';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthModule } from "./auth/auth.module";

import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { FlatpickrModule } from 'angularx-flatpickr';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatTableModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatSortModule,
  MatChipsModule,
  MatButtonModule,
  MatPaginatorIntl,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatSelectModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatDividerModule
} from '@angular/material';

import { getptBRPaginatorIntl } from './_utils/ptBR-paginator-intl';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SolarComponent } from './theme/pages/default/solar/solar.component';
import { AuthInterceptor } from './auth/_interceptors/auth-interceptor';
import { ConsorcioComponent } from './theme/pages/default/consorcio/consorcio.component';
import { LogisticaComponent } from './theme/pages/default/logistica/logistica.component';
import { NgxMaskModule } from 'ngx-mask';
import { IndexComponent } from './theme/pages/default/index/index.component';
import { PessoaComponent } from './theme/pages/default/pessoa/pessoa.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PontoDeVendaComponent } from './theme/pages/default/ponto-de-venda/ponto-de-venda.component';
import { PontosDeVendaComponent } from './theme/pages/default/pontos-de-venda/pontos-de-venda.component';
import { MenuPerfilComponent } from './theme/pages/default/menu-perfil/menu-perfil.component';
import { TiposPessoaComponent } from './theme/pages/default/tipos-pessoa/tipos-pessoa.component';
import { TipoPessoaComponent } from './theme/pages/default/tipo-pessoa/tipo-pessoa.component';
import { ResizeColumnDirective } from './_directives/resize-column.directive';
import { ImportarLancamentosComponent } from './theme/pages/default/importar-lancamentos/importar-lancamentos.component';
import { ConfirmModalComponent } from './theme/components/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from './theme/components/success-modal/success-modal.component';
import { ErrorModalComponent } from './theme/components/error-modal/error-modal.component';
import { Select2Module } from 'ng2-select2';
import { SharedModule } from './theme/components/shared.module';
import { OperacaoEmpresaShowComponent } from './theme/pages/default/operacao-empresa/operacao-empresa-show/operacao-empresa-show.component';
import { ProdutosNovoComponent } from './theme/pages/default/produtos-novo/produtos-novo.component';
import { ProdutoNovoComponent } from './theme/pages/default/produto-novo/produto-novo.component';
import { BancosComponent } from './theme/pages/default/bancos/bancos.component';
import { BancoComponent } from './theme/pages/default/banco/banco.component';
import { ConveniosComponent } from './theme/pages/default/convenios/convenios.component';
import { PoliticaProdutosComponent } from './theme/pages/default/politica-produtos/politica-produtos.component';
import { CanaisComponent } from './theme/pages/default/canais/canais.component';
import { CanalComponent } from './theme/pages/default/canal/canal.component';
import { ConvenioComponent } from './theme/pages/default/convenio/convenio.component';
import { PoliticaProdutoComponent } from './theme/pages/default/politica-produto/politica-produto.component';
import { MovimentacoesComponent } from './theme/pages/default/movimentacoes/movimentacoes.component';
import { MeComponent } from './theme/pages/default/me/me.component';

@NgModule({
  declarations: [
    ThemeComponent,
    AppComponent,
    SolarComponent,
    ConsorcioComponent,
    LogisticaComponent,
    IndexComponent,
    PessoaComponent,
    PontoDeVendaComponent,
    PontosDeVendaComponent,
    MenuPerfilComponent,
    TiposPessoaComponent,
    TipoPessoaComponent,
    ResizeColumnDirective,
    ProdutosNovoComponent,
    ProdutoNovoComponent,
    CanaisComponent,
    CanalComponent,
    OperacaoEmpresaShowComponent,
    ImportarLancamentosComponent,
    ConfirmModalComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    BancoComponent,
    BancosComponent,
    ConveniosComponent,
    PoliticaProdutosComponent,
    ConvenioComponent,
    PoliticaProdutoComponent,
    MovimentacoesComponent,
    MeComponent
  ],
  imports: [
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeRoutingModule,
    AuthModule,
    FormsModule,
    HttpClientModule,
    CurrencyMaskModule,
    ChartsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    NgxMaskModule.forRoot(),
    NgxMyDatePickerModule.forRoot(),
    FlatpickrModule.forRoot(),
    NgbModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatIconModule,
    CommonModule,
    MatDialogModule,
    MatButtonToggleModule,
    Select2Module,
    SharedModule,
    MatDividerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ScriptLoaderService,
    ThemeService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: MatPaginatorIntl,
      useValue: getptBRPaginatorIntl()
    },
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [],
  entryComponents: [ConfirmModalComponent, SuccessModalComponent],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
