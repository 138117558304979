import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from 'app/_services/rest.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-pontos-de-venda',
  templateUrl: "./pontos-de-venda.component.html",
  styleUrls: ["./pontos-de-venda.component.less"]
})
export class PontosDeVendaComponent implements OnInit {
  private searchTerms = new Subject<string>();
  filteredOptions!: Observable<string[]>;
  myControl = new FormControl('');

  constructor(private _restService: RestService,
    private router: Router) {
  }

  ngOnInit() {
    // GET pra listar uma quantidade x de clientes
    this.filteredOptions = this.searchTerms.pipe(
      // wait 250ms after each keystroke before considering the term
      debounceTime(250),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap(
        (term: string) => this._restService.getPontosDeVendaPessoa(term).map(item => item.results)
      )
    );
  }

  cleanTerms(term: string): string {
    return term.replace(/[./-]/g, '');
  }

  // Autocomplete
  search(term: string): void {
    if (term.length > 2) {
      const cleanedTerm = this.cleanTerms(term);
      this.searchTerms.next(cleanedTerm.toUpperCase());
    }
  }

  displayFn(pontoDeVenda: any): string {
    return pontoDeVenda && pontoDeVenda.text ? pontoDeVenda.text : '';
  }

  toPontoDeVenda() {
    if (this.myControl.value.id) {
      this.router.navigate(['/ponto-de-venda', this.myControl.value.id]);
    }
  }
}
