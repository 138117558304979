import { Location } from "@angular/common";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router} from '@angular/router';

import { RestService } from "../../../../../_services/rest.service";
import { UtilsService } from "../../../../../_services/utils.service";

import { FormService } from "../../../../../_services/form.service";
import { SelectService } from "../../../../../_services/select.service";
import { ConstantsService } from "../../../../../_services/constants.service";

import { Select2OptionData } from "ng2-select2";
import { INgxMyDpOptions } from "ngx-mydatepicker";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { pessoaTipoEnum } from "app/_enums/pessoaTipo.enum";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-operacao-empresa-show",
  templateUrl: "./operacao-empresa-show.component.html",
  styleUrls: ["./operacao-empresa-show.component.less"],
})
export class OperacaoEmpresaShowComponent implements OnInit {
  operacoesId: number | null = null;
  pessoaTipoEnum = pessoaTipoEnum;

  public loading = { table: false, rest: false };
  public pdvs: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];
  public fornecedores: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];
  public codigosConta: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];
  selectedValues: string[];
  public gestores: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];
  public bancos: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];
  public matrizesCertificacao: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definida" },
  ];
  public matrizesMoto: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definida" },
  ];
  public cidadesEmissao: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definida" },
  ];
  public convenios: Array<Select2OptionData> = [
    { id: "undefined", text: "Convênio não definido" },
  ];
  public contas: Array<Select2OptionData> = [
    { id: "undefined", text: "Conta não definida" },
  ];
  public vendedores: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];
  public operacoesSelect: Array<Select2OptionData> = [
    { id: "undefined", text: "Não definido" },
  ];


  //FUNCIONALIDADES RELACIONADAS A BANCO 

  public bancoItem: any = {};
  //public gestorItem: any = { id: 'undefined', text: 'Banco não definido' };

  public operacaoEmpresa: any = {};

  switchM: boolean = false;

  myOptions: INgxMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
  };

  public msgModal: string = "";

  //form group só é usado para a parte de "gestao" da tela
  gestaoForm = new FormGroup({
    diretor: new FormControl("", Validators.required),
    gerenteComercial: new FormControl("", Validators.required),
    supervisorComercial: new FormControl("", Validators.required),
    gestorFranquia: new FormControl(""),
  });

  private searchTermsDiretor = new Subject<string>();
  private searchTermsGerenteComercial = new Subject<string>();
  private searchTermsSupervisorComercial = new Subject<string>();
  private searchTermsGestorFranquia = new Subject<string>();

  filteredOptionsDiretor!: Observable<string[]>;
  filteredOptionsGerenteComercial!: Observable<string[]>;
  filteredOptionsSupervisorComercial!: Observable<string[]>;
  filteredOptionsGestorFranquia!: Observable<string[]>;

  public unidades: Array<Select2OptionData> = [
    { id: "undefined", text: "Unidade não definida" },
  ];
  public unidadeSelect: any = null;
  public unidadeSelectLista: any = null;

  public pdvsFiltro: Array<Select2OptionData> = [
    { id: "undefined", text: "PDV não definido" },
  ];
  public pdvsLista: Array<Select2OptionData> = [
    { id: "undefined", text: "PDV não definido" },
  ];
  public pdvSelectLista: any = null;

  constructor(
    private route: ActivatedRoute,
    private _restService: RestService,
    private _formService: FormService,
    private _utilsService: UtilsService,
    private _selectService: SelectService,
    private _constantsService: ConstantsService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private router: Router
  ) {
    this.route.paramMap.subscribe((params) => {
      if (params.get("operacoesId") != null) {
        this.operacoesId = parseInt(params.get("operacoesId"));
      } else {
        this.operacoesId = null;
      }
    });
    this.operacaoEmpresa = this.getDefaultOperacoes();
    // console.log("essas são as operacoes empresas: ", this.operacaoEmpresa)
    this.getGestao();
  }

  ngOnInit() {
    this.loading.rest = true;

    const pdvs$ = this._selectService.getPessoaPorTipo(pessoaTipoEnum.PDV);
    const fornecedores$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.FORNECEDOR
    );
    const codigosConta$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.CONTA_QUALQUER
    );
    const matrizesCertificacao$ = this._selectService.getOperacoesMatriz(
      this._constantsService.ID_CONVENIO_CERTIFICACAO
    );
    const matrizesMoto$ = this._selectService.getOperacoesMatriz(
      this._constantsService.ID_CONVENIO_MOTOS
    );
    const cidadesEmissao$ = this._restService.getCidadesNfse();
    const convenios$ = this._selectService.getConvenios();
    const operacoesContas$ = this._selectService.getOperacoesContas();
    const gestores$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.CONTROLE_DE_SALDO
    );
    const bancos$ = this._selectService.getBancos();
    const vendedores$ = this._selectService.getPessoaPorTipo(
      pessoaTipoEnum.CONTROLE_DE_SALDO
    );
    const operacoesSelect$ = this._selectService.getUnidades();

    forkJoin([
      pdvs$,
      fornecedores$,
      codigosConta$,
      matrizesCertificacao$,
      matrizesMoto$,
      cidadesEmissao$,
      convenios$,
      operacoesContas$,
      gestores$,
      bancos$,
      vendedores$,
      operacoesSelect$,
    ]).subscribe(
      ([
        pdvsRes,
        fornecedoresRes,
        codigosContaRes,
        matrizesCertificacaoRes,
        matrizesMotoRes,
        cidadesEmissaoRes,
        conveniosRes,
        operacoesContasRes,
        gestoresRes,
        bancosRes,
        vendedoresRes,
        operacoesSelectRes,
      ]) => {
        this.pdvs = pdvsRes;
        this.fornecedores = fornecedoresRes;
        this.codigosConta = codigosContaRes;
        this.matrizesCertificacao = matrizesCertificacaoRes;
        this.matrizesMoto = matrizesMotoRes;
        this.cidadesEmissao = cidadesEmissaoRes;
        this.convenios = conveniosRes;
        this.contas = operacoesContasRes;
        this.gestores = gestoresRes;
        this.bancos = bancosRes;
        this.vendedores = vendedoresRes;
        this.operacoesSelect = operacoesSelectRes;
        this.loading.rest = false;
        if (this.operacaoEmpresa.matriz) this.switchM = true;
        else this.switchM = false;
        if (this.operacoesId) {
          this._restService
            .getOperacaoEmpresa(this.operacoesId)
            .subscribe((operacaoEmpresaRes) =>
              this.verCadastro(operacaoEmpresaRes)
            );
        }
      }
    );
  }

  getGestao() {
    this.filteredOptionsDiretor = this.searchTermsDiretor.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((term: string) =>
        this._restService.buscarPessoaSelect(term).map((item) => item.results)
      )
    );
    this.filteredOptionsGerenteComercial =
      this.searchTermsGerenteComercial.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((term: string) =>
          this._restService.buscarPessoaSelect(term).map((item) => item.results)
        )
      );
    this.filteredOptionsSupervisorComercial =
      this.searchTermsSupervisorComercial.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((term: string) =>
          this._restService.buscarPessoaSelect(term).map((item) => item.results)
        )
      );
    this.filteredOptionsGestorFranquia = this.searchTermsGestorFranquia.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((term: string) =>
        this._restService.buscarPessoaSelect(term).map((item) => item.results)
      )
    );
  }

  searchGestao(text: string, tipo: string): void {
    if (text.length > 2) {
      const term = `texto=${text.toUpperCase()}&all=${tipo}`;
      switch (tipo) {
        case pessoaTipoEnum.DIRETOR:
          this.searchTermsDiretor.next(term);
          break;
        case pessoaTipoEnum.GERENTE_COMERCIAL:
          this.searchTermsGerenteComercial.next(term);
          break;
        case pessoaTipoEnum.SUPERVISOR_COMERCIAL:
          this.searchTermsSupervisorComercial.next(term);
          break;
        case pessoaTipoEnum.GESTOR_FRANQUIA:
          this.searchTermsGestorFranquia.next(term);
          break;

        default:
          break;
      }
    }
  }

  displayFn = (pessoa: any): string => {
    return pessoa && pessoa.text ? pessoa.text : "";
  };

  private getDefaultOperacoes() {
    this.bancoItem = {
      banco: { id: "undefined", text: "Banco não definido" },
      pessoa: { id: "undefined", text: "Fornecedor não definido" },
      percSubestabelecimento: undefined,
      codPromotora: undefined,
    };

    return {
      id: null,
      matriz: undefined,
      descricao: undefined,
      substabelecida: false,
      cnpj: undefined,
      matrizMoto: undefined,
      matrizCertificacao: undefined,
      codOperacaoSucessora: undefined,
      empresaParceiraSolar: undefined,
      codPdv: undefined,
      codFornecedor: undefined,
      codContaCaixa: undefined,
      codContaConciliar: undefined,
      nfs: undefined,
      gestor: undefined,
      bancos: [],
    };
  }

  public verCadastro(operacaoEmpresa) {
    this.gestaoForm.reset();
    this.operacaoEmpresa = operacaoEmpresa;
    this.operacaoEmpresa.cnpj = this.operacaoEmpresa.cnpj
      ? this.keyUpCpfCnpj(operacaoEmpresa.cnpj, null)
      : undefined;
    console.log(this.operacaoEmpresa);
    const diretor = this.operacaoEmpresa.diretor;
    const gerenteComercial = this.operacaoEmpresa.gerenteComercial;
    const supervisorComercial = this.operacaoEmpresa.supervisorComercial;
    const gestorFranquia = this.operacaoEmpresa.gestorFranquia;
    console.log({
      diretor,
      gerenteComercial,
      supervisorComercial,
      gestorFranquia,
    });
    this.gestaoForm.patchValue({
      diretor,
      gerenteComercial,
      supervisorComercial,
      gestorFranquia,
    });

    this.switchM = false;

    scrollTo(0, 0);
    this.cdr.detectChanges();
  }

  public salvar() {
    let msgErrors = this.validaAtributosOperacaoEmpresa();
    if (this.gestaoForm.get("diretor").value) {
      this.operacaoEmpresa.diretor = this.gestaoForm.get("diretor").value;
    } else {
      this.operacaoEmpresa.diretor = null;
      msgErrors.push("O Diretor é obrigatório!");
    }
    if (this.gestaoForm.get("gerenteComercial").value) {
      this.operacaoEmpresa.gerenteComercial =
        this.gestaoForm.get("gerenteComercial").value;
    } else {
      this.operacaoEmpresa.gerenteComercial = null;
      msgErrors.push("O Gerente Comercial é obrigatório!");
    }
    if (this.gestaoForm.get("supervisorComercial").value) {
      this.operacaoEmpresa.supervisorComercial = this.gestaoForm.get(
        "supervisorComercial"
      ).value;
    } else {
      this.operacaoEmpresa.supervisorComercial = null;
      msgErrors.push("O Supervisor Comercial é obrigatório!");
    }
    if (this.gestaoForm.get("gestorFranquia").value) {
      this.operacaoEmpresa.gestorFranquia =
        this.gestaoForm.get("gestorFranquia").value;
    } else {
      this.operacaoEmpresa.gestorFranquia = null;
    }
    console.log(this.operacaoEmpresa);
    if (msgErrors.length > 0 || this.gestaoForm.invalid) {
      console.log("teste1");
      this.formatArrayMsgModal(msgErrors);
    } else {
      console.log("teste2");
      this.operacaoEmpresa.cnpj = this.operacaoEmpresa.cnpj
        ? this._utilsService.formatCpfCnpj(this.operacaoEmpresa.cnpj)
        : null;

      this.loading.rest = true;
      if (this.operacaoEmpresa.id == null) {
        this._restService.addOperacaoEmpresa(this.operacaoEmpresa).subscribe(
          (operacaoEmpresa) => {
            this.loading.rest = false;
            this.verLista();
          },
          (error) => {
            this._utilsService.alertErro(error);
            this.loading.rest = false;
          }
        );
      } else {
        this._restService.updateOperacaoEmpresa(this.operacaoEmpresa).subscribe(
          (operacaoEmpresa) => {
            this.loading.rest = false;
            this.verLista();
          },
          (error) => {
            this._utilsService.alertErro(error);
            this.loading.rest = false;
          }
        );
      }
    }
  }

  public keyUpCpfCnpj(valString, event) {
    return this._formService.format(valString);
  }


  formatArrayMsgModal(array) {
    this.msgModal = "";
    for (var i = 0; i < array.length; i++) {
      this.msgModal += array[i] + "\n";
    }

    this.showModal(this.msgModal);
  }

  showModal(msg: string) {
    jQuery.noConflict();
    this.msgModal = msg;
    console.warn(msg);

  }
  

  private validaAtributosOperacaoEmpresa() {
    var msgErrors: Array<String> = [];
    if (
      this.operacaoEmpresa.descricao == undefined ||
      this.operacaoEmpresa.descricao.trim() == ""
    ) {
      msgErrors.push("O campo Descrição é obrigatório!");
    }

    if (
      this.operacaoEmpresa.codContaCaixa == undefined ||
      this.operacaoEmpresa.codContaCaixa == "undefined"
    ) {
      msgErrors.push("O campo Código Conta Caixa é obrigatório!");
    }

    if (
      this.operacaoEmpresa.codContaConciliar == undefined ||
      this.operacaoEmpresa.codContaConciliar == "undefined"
    ) {
      msgErrors.push("O campo Código Conta Conciliar é obrigatório!");
    }

    if (
      this.operacaoEmpresa.codControleSaldos == undefined ||
      this.operacaoEmpresa.codControleSaldos == "undefined"
    ) {
      msgErrors.push("O campo Controle Saldos é obrigatório!");
    }

    if (
      this.operacaoEmpresa.codSupervisorArea == undefined ||
      this.operacaoEmpresa.codSupervisorArea == "undefined"
    ) {
      msgErrors.push("O campo Supervisor Área é obrigatório!");
    }

    if (
      this.operacaoEmpresa.codGestor == undefined ||
      this.operacaoEmpresa.codGestor == "undefined"
    ) {
      msgErrors.push("O campo Gestor é obrigatório!");
    }

    if (
      this.operacaoEmpresa.matriz &&
      this.operacaoEmpresa.matriz.convenio == undefined
    ) {
      msgErrors.push("O campo Convênio da Matriz é obrigatório!");
    }

    return msgErrors;
  }

  excluirBanco(bancoIndex: number) {
    console.log(bancoIndex);
    if (confirm("Você deseja excluir este banco?")) {
      this.operacaoEmpresa.bancos.splice(bancoIndex, 1);
    }
  }

  public changedcidadeNfse(e: any): void {
    this.operacaoEmpresa.nfs = e.value != "undefined" ? e.value : undefined;
  }

  public changedMatrizMoto(e: any): void {
    this.operacaoEmpresa.matrizMoto =
      e.value != "undefined" ? e.value : undefined;
  }

  public changedMatrizCertificacao(e: any): void {
    this.operacaoEmpresa.matrizCertificacao =
      e.value != "undefined" ? e.value : undefined;
  }

  public changedOperacaoSucessora(e: any): void {
    this.operacaoEmpresa.codOperacaoSucessora =
      e.value != "undefined" ? e.value : undefined;
  }

  public changedPdv(e: any): void {
    this.operacaoEmpresa.codPdv = e.value != "undefined" ? e.value : undefined;
  }

  public changedFornecedor(e: any): void {
    this.operacaoEmpresa.codFornecedor =
      e.value != "undefined" ? e.value : undefined;
  }


  public changedContaCaixa(e: any): void {
    this.operacaoEmpresa.codContaCaixa =
      e.value != "undefined" ? e.value : undefined;
  }

  public changedContaCaixaConciliar(e: any): void {
    this.operacaoEmpresa.codContaConciliar =
      e.value != "undefined" ? e.value : undefined;
  }

  public changedVendedor(e: any, field): void {
    console.log(e);
    this.operacaoEmpresa[field] = e.value != "undefined" ? e.value : undefined;
  }

 
  public changedConvenio(e: any): void {
    console.log(" esse é o convenio: ", e.value )
    this.operacaoEmpresa.matriz.convenio =
      e.value != "undefined" ? e.value : undefined;
  }

  public changedConta(e: any): void {
    console.log(" essa é a conta: ", e.value )
    this.operacaoEmpresa.matriz.conta =
      e.value != "undefined" ? e.value : undefined;
  }

  changedMatriz() {
    console.log("essa é a matriz: ", this.operacaoEmpresa);
    if (this.operacaoEmpresa.matriz == undefined) {
      this.operacaoEmpresa.matriz = {
        id: undefined,
        convenio: undefined,
        linkEmail: undefined,
      };
    } else {
      this.operacaoEmpresa.matriz = undefined;
    }
  }

  verLista(): void {
    this.location.back();
  }

  goBack() {
    this.router.navigate(['/operacao-empresa']);
  }
}


