import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidation {

    valorDiferenteDeZero(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;
            if (!value) {
                return null;
            }

            const valueValid = value != 0;
            return !valueValid ? { valorDiferenteDeZero: valueValid } : null;
        }
    }
    
    isValidCNPJ(): ValidatorFn {
        return (control:AbstractControl) : ValidationErrors | null => {
    
            const strCNPJ = control.value;
    
            if (!strCNPJ) {
                return null;
            }
    
            if (strCNPJ == '' ||
                strCNPJ == '00000000000000' ||
                strCNPJ == '11111111111111' ||
                strCNPJ == '22222222222222' ||
                strCNPJ == '33333333333333' ||
                strCNPJ == '44444444444444' ||
                strCNPJ == '55555555555555' ||
                strCNPJ == '66666666666666' ||
                strCNPJ == '77777777777777' ||
                strCNPJ == '88888888888888' ||
                strCNPJ == '99999999999999') {
                    return { cnpjInvalid: true };
                }
                
    
            // Valida DVs
            var tamanho = strCNPJ.length - 2
            var numeros = strCNPJ.substring(0, tamanho);
            var digitos = strCNPJ.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
    
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
    
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) {
                return {cnpjInvalid:true};
            }
                
            tamanho = tamanho + 1;
            numeros = strCNPJ.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
    
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
    
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(1))
                return {cnpjInvalid:true};
    
            return null;
        }
    }
    
    isValidCPF(): ValidatorFn {
        return (control:AbstractControl) : ValidationErrors | null => {
    
            const strCPF = control.value;
    
            if (!strCPF) {
                return null;
            }
    
            var soma;
            var resto;
            soma = 0;
    
            if (strCPF == '00000000000' ||
                strCPF == '11111111111' ||
                strCPF == '22222222222' ||
                strCPF == '33333333333' ||
                strCPF == '44444444444' ||
                strCPF == '55555555555' ||
                strCPF == '66666666666' ||
                strCPF == '77777777777' ||
                strCPF == '88888888888' ||
                strCPF == '99999999999') return { cpfInvalid: true };
    
            for (let i = 1; i <= 9; i++) {
                soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                resto = (soma * 10) % 11;
            }
    
            if ((resto == 10) || (resto == 11)) {
                resto = 0;
            }
    
            if (resto != parseInt(strCPF.substring(9, 10))) {
                return { cpfInvalid: true };
            }
    
            soma = 0;
            for (let i = 1; i <= 10; i++) {
                soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                resto = (soma * 10) % 11;
            }
    
            if ((resto == 10) || (resto == 11)) {
                resto = 0;
            }
            if (resto != parseInt(strCPF.substring(10, 11))) {
                return { cpfInvalid: true };
            }
    
            return null;
        }
    }
}